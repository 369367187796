import React from "react";
import Header from "organisms/Header";
import Footer from "organisms/Footer";

const Layout = ({ scrollable, showFooter, children , className }) => {
  return (
    <div className={!scrollable ? "lg:h-screen bg-white overflow-hidden" : ""}>
      <Header type="homeScreen" classForContainer={className}/>
      {children}
      {showFooter && <Footer type="type2" hideInmobile={false} />}
    </div>
  );
};

export default Layout;
