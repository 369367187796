import React from "react";
import PropTypes from "prop-types";

const Anchor = ({ className = "", title, href, ...OtherProps }) => {
  return (
    <a className={className} href={href} {...OtherProps}>
      {title}
    </a>
  );
};

Anchor.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
};

export default Anchor;
