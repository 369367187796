import React, { useRef } from "react";
import { motion, useTransform, useScroll, circInOut } from "framer-motion";

const ParallaxItem = ({ children }) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });
  const translateYLeft = useTransform(
    scrollYProgress,
    [0, 0.5],
    ["100%", "0%"]
  );
  const opacity = useTransform(scrollYProgress, [0, 0.3], ["0", "1"]);
  return (
    <div ref={ref}>
      <motion.div style={{ translateY: translateYLeft, opacity: opacity }}>
        {children}
      </motion.div>
    </div>
  );
};

const HomePayMonthOnMonth = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"],
  });

  const translateYImage1 = useTransform(
    scrollYProgress,
    [0, 0.3],
    ["35%", "0%"]
  );
  const translateYImage2 = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["65%", "0%"]
  );
  const translateYImage3 = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["75%", "0%"]
  );
  return (
    <div className="relative min-h-[100vh]">
      <div className="sticky top-[20vh] flex flex-col  md:flex-row max-w-screen-lg px-8 mx-auto relative z-2 gap-16 md:gap-12 pb-14 md:pb-24">
        <motion.div className="w-full">
          <ParallaxItem>
            <h2 className="mb-4 text-3xl font-[600] md:text-4xl md:mt-[1.2rem]">
              Pay month on month,
              <br />
              drive the future.
            </h2>
          </ParallaxItem>
          <ParallaxItem>
            <p className="text-[#747E8A] max-w-[280px] pb-12 md:pb-24">
              With our flexible plans, you no longer need to worry about
              financing your dream car.
            </p>
          </ParallaxItem>

          <ParallaxItem>
            <h2 className="mb-4 text-3xl font-[600] md:text-4xl pt-8 md:pt-16 border-t-2 border-solid border-secondary-700 max-w-[85%]">
              We have you covered.
            </h2>
          </ParallaxItem>
          <ParallaxItem>
            <ul>
              {[
                "No insurance hassles",
                "No registration and road taxes",
                "No maintenance visits",
                "No towing or repair worries",
              ].map((item) => {
                return (
                  <motion.li key={item} className="text-[#747E8A] py-1">
                    {item}
                  </motion.li>
                );
              })}
            </ul>
          </ParallaxItem>
        </motion.div>
        <div ref={ref}>
          <motion.div className="w-full">
            <div className="grid grid-rows-3 grid-cols-2 gap-2 h-full md:mt-[2.8rem]">
              <motion.div
                style={{ translateY: translateYImage1 }}
                className="row-span-1 col-span-1 bg-white text-left"
              >
                <img
                  src="/images/pm-image-2.png"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div
                style={{ translateY: translateYImage2 }}
                className="row-span-1 col-span-1 bg-white text-left"
              >
                <img
                  src="/images/pm-image-3.png"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div
                style={{ translateY: translateYImage3 }}
                className="row-span-2 col-span-2 bg-white text-left"
              >
                <img
                  src="/images/pm-image-1.png"
                  alt=""
                  className="w-full h-full object-cover"
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HomePayMonthOnMonth;
