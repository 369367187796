import Anchor from "atoms/Anchor";
import ParallaxImage from "atoms/ParallaxImage";
import { motion, useTransform, useScroll } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import HomePayMonthOnMonth from "./HomePayMonthOnMonth";

const featuresList = [
  {
    title: "Flat monthly fee & everything inclusive ",
    description: "Insurance, repair & maintenance, road taxes & registration.",
  },
  {
    title: "Immediately available ",
    description:
      "Book now & get your car immediately delivered without waiting time.",
  },
  {
    title: "24/7 customer service & roadside assistance",
    description:
      "You can reach us 24/7 for all your needs, we are here to assist you.",
  },
  {
    title: "No deposits or down payments",
    description:
      "There are no downpayments or deposits, just a monthly subscription.",
  },
  {
    title: "Fully flexible",
    description: "Upgrade your car, Pause or Cancel subscription anytime.",
  },
  {
    title: "Replacement car guaranteed",
    description:
      "You will always get a replacement car in case of any repairs or issues.",
  },
  {
    title: "Loan-free access",
    description: "No complex financial paperwork or complex leasing terms.",
  },
];
const priceTier = [
  {
    name: "Minimum 1 month",
    description: "Super flexible",
    recommended: false,
    ae: [
      "2000 kms included in the plan",
      "0.5 AED per additional kms",
      "Super flexible - renews every month",
    ],
    de: [
      "1000 kms included in the plan",
      "40 cents per additional kms",
      "Super flexible - renews every month",
    ],
  },
  {
    name: "Minimum 6 months",
    description: "Save 10%",
    recommended: false,
    ae: [
      "14000 kms included in the plan",
      "0.4 AED per additional kms",
      "Renews month on month after first 6 months",
      "Save 10% immediately",
    ],
    de: [
      "7000 kms included in the plan",
      "38 cents per additional kms",
      "Renews month on month after first 6 months",
      "Save 10% immediately",
    ],
  },
  {
    name: "Minimum 12 months",
    description: "Save 25%",
    recommended: true,
    ae: [
      "30000 kms included in the plan",
      "0.35 AED per additional kms",
      "Renews month on month after first 12 months",
      "Save 25% immediately",
    ],
    de: [
      "15000 kms included in the plan",
      "35 cents per additional kms",
      "Renews month on month after first 12 months",
      "Save 25% immediately",
    ],
  },
];
const fadeInAnimateVaraints = {
  intial: {
    opacity: 0,
    y: 120,
  },
  animate: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * i,
      type: "spring",
      bounce: 0.35,
      duration: 1,
    },
  }),
};
const fadeInAnimateViewport = {
  once: true,
  amount: 0.3,
};
const fadeInAnimateProps = {
  variants: fadeInAnimateVaraints,
  initial: "intial",
  whileInView: "animate",
  viewport: fadeInAnimateViewport,
};

const HomepageTemplate = () => {
  const router = useRouter();

  const carRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: carRef,
    offset: ["start start", "end end"],
  });
  const filter = useTransform(
    scrollYProgress,
    [0, 0.3, 1],
    ["brightness(0)", "brightness(0)", " brightness(1)"]
  );
  const translateY = useTransform(scrollYProgress, [0, 1], ["5%", "-5%"]);

  return (
    <div className="min-h-screen bg-neutral-900">
      <div className="relative pb-8" ref={carRef}>
        <div className="absolute bottom-0 bg-primary-200 block w-full h-[30vh] flex items-end justify-center"></div>
        <div className="relative max-w-screen-lg px-8 mx-auto pointer-events-none">
          <div className="flex items-center justify-center  min-h-[calc(100vh-74px)] md:min-h-[calc(90vh-74px)] pointer-events-auto">
            <div className="relative max-w-screen-md mt-[-35vh] md:mt-[-25vh]">
              <motion.p
                initial={{
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,

                  transition: {
                    delay: 0.05 * 3,
                    type: "spring",
                    bounce: 0.15,
                    duration: 1.2,
                  },
                }}
                viewport={{ once: true }}
                className="text-center text-gray-500 uppercase mb-4 text-[1.2rem]"
              >
                introducing
              </motion.p>
              <h1 className="text-center font-bold text-primary-200">
                <motion.span
                  initial={{
                    rotate: "-12deg",
                    opacity: 0,
                    scale: 0.6,
                  }}
                  whileInView={{
                    opacity: 1,
                    rotate: 0,
                    scale: 1,
                    transition: {
                      delay: 0.05 * 5,
                      type: "spring",
                      bounce: 0.1,
                      duration: 1,
                    },
                  }}
                  viewport={{ once: true }}
                  className="block text-3xl md:text-3xl lg:text-4xl xl:text-5xl"
                >
                  A revolutionary way to
                </motion.span>
                <motion.span
                  initial={{
                    rotate: "-5deg",
                    opacity: 0,
                    scale: 0.9,
                  }}
                  whileInView={{
                    opacity: 1,
                    rotate: 0,
                    scale: 1,
                    transition: {
                      delay: 0.05 * 10,
                      type: "spring",
                      bounce: 0.1,
                      duration: 1,
                    },
                  }}
                  viewport={{ once: true }}
                  className="block mt-2 text-4xl md:text-5xl lg:text-6xl xl:text-7xl"
                >
                  own your car
                </motion.span>
              </h1>
              <div className="flex items-center justify-center gap-6 mt-8 ">
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,

                    transition: {
                      delay: 0.05 * 10,
                      type: "spring",
                      bounce: 0.15,
                      duration: 1.4,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <Link
                    href={`/en/${router.query.country}#knowMore`}
                    className="cursor-pointer"
                    scroll={false}
                  >
                    <a className="text-secondary-400" id='know-more'>Know More</a>
                  </Link>
                </motion.div>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,

                    transition: {
                      delay: 0.05 * 12,
                      type: "spring",
                      bounce: 0.15,
                      duration: 1.4,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <Link
                    href={`/en/${router.query.country}/all-cars`}
                    className="cursor-pointer"
                  >
                    <a id='see-all-cars-button' className="inline-block bg-secondary-400 text-neutral-900 px-5 py-2.5 leading-[100%] rounded-[10px]">
                      See All Cars
                    </a>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center justify-center pointer-events-auto"
            id="knowMore"
          >
            <div className="relative max-w-screen-md pt-[10vh] ">
              <h2 className="text-center font-[500] text-primary-200">
                <span className="block text-3xl md:text-3xl lg:text-4xl xl:text-5xl">
                  Drive your new car with
                </span>
              </h2>
              <ul className="font-[500] text-center text-primary-200 pt-12 max-w-full">
                {[
                  "No bank loans",
                  "No down payments",
                  "No financial burdens",
                  "No impact on credit score",
                ].map((item) => {
                  return (
                    <li key={item}>
                      <motion.span
                        initial={{ scale: 1, opacity: 0.5 }}
                        whileInView={{ scale: 1.2, opacity: 1 }}
                        viewport={{ margin: "-40% 0px -60% 0px" }}
                        className="block text-bold pt-4 pb-4 md:pt-8 md:pb-8 text-2xl md:text-2xl lg:text-4xl xl:text-4xl max-w-[90%] text-center mx-auto"
                      >
                        {item}
                      </motion.span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{
              opacity: 1,

              y: 0,
              transition: { delay: 0.05 * 18, duration: 1.2 },
            }}
            className="sticky bottom-0 pointer-events-none"
          >
            <motion.img
              src="/images/audi-home.png"
              alt=""
              className="block w-full h-[50vh] object-contain relative z-2 "
              style={{
                translateX: translateY,
                filter: filter,
              }}
            />
          </motion.div>
        </div>
      </div>
      <div className="bg-primary-200 text-neutral-900 relative mt-[-8rem] md:mt-[-40px]">
        <div className="block h-[10rem] md:h-[30vh] relative">
          <Link
            href={`/en/${router.query.country}/all-cars`}
            className="cursor-pointer"
          >
            <a  id='see-all-cars-button-2' className="inline-block bg-neutral-900 text-primary-200 px-5 py-3 leading-[100%] rounded-[10px] absolute z-2 left-1/2 top-8 transform translate-x-[-50%]">
              See All Cars
            </a>
          </Link>
          {/* prettier-ignore */}
          <motion.svg initial={{strokeDashoffset:1814,strokeDasharray:1814}} whileInView={{strokeDashoffset:0,strokeDasharray:1814}} className="w-full block transition-all duration-1000 ease-linear" viewBox="0 0 1580 645" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-22 50.2852H921.459C968.539 52.4947 1072.71 74.716 1112.78 145.925C1162.85 234.937 1354.81 523.277 1562.82 597.138" stroke="url(#paint0_linear_4593_369)" strokeWidth="100"/>
            <defs>
            <linearGradient id="paint0_linear_4593_369" x1="438.367" y1="21.4288" x2="1456.21" y2="437.235" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CDB7A4"/>
            <stop offset="0.838542" stopColor="#F2ECE4"/>
            </linearGradient>
            </defs>
          </motion.svg>
        </div>
        <HomePayMonthOnMonth />
      </div>
      <div className="bg-primary-200 text-neutral-900">
        <div className="relative flex flex-col items-start md:flex-row max-w-screen-lg px-8 mx-auto pt-6 md:pt-24 gap-12 pb-24">
          <div className="w-full static md:sticky  top-[20%]">
            <motion.h2
              {...fadeInAnimateProps}
              custom={2}
              className="mb-8 text-3xl font-[600] md:text-4xl max-w-[80%]"
            >
              Explore possibilities the smart way.
            </motion.h2>
            <motion.div {...fadeInAnimateProps} custom={3}>
              <Link
                href={`/en/${router.query.country}/all-cars`}
                className="cursor-pointer"
              >
                <a id='see-all-cars-button-3' className="inline-block bg-neutral-900 text-primary-200 px-5 py-2.5 leading-[100%] rounded-[10px]">
                  See All Cars
                </a>
              </Link>
            </motion.div>
          </div>
          <div className="w-full">
            <motion.h3
              {...fadeInAnimateProps}
              custom={2}
              className="mb-8 text-2xl font-[600] "
            >
              Unique benefits that fit your lifestyle
            </motion.h3>
            <ul className="-mb-8">
              {featuresList.map((item, index) => {
                return (
                  <motion.li
                    {...fadeInAnimateProps}
                    custom={index}
                    key={index + item}
                    className="flex flex-col mb-8"
                  >
                    <strong>{item.title}</strong>
                    <span className="text-[#2b2b2b66]">{item.description}</span>
                  </motion.li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-neutral-900 text-primary-200 relative min-h-[600px]">
        <div className="absolute inset-0 bg-neutral-900 bg-[url(/images/zenovo-vector.png)] bg-contain bg-top top-12 bg-no-repeat"></div>
        <div className="absolute top-12 left-0 block w-[30%] ">
          {/* prettier-ignore */}
          <motion.svg initial={{strokeDashoffset:754,strokeDasharray:754}} whileInView={{strokeDashoffset:0,strokeDasharray:754}} id="circlualrPath" className="w-full block  transition-all duration-1000 ease-linear" viewBox="0 0 458 547" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.6" d="M-42 44C113.261 47.2346 407.611 160.446 414.081 545.365" stroke="url(#paint0_linear_4593_371)" strokeWidth="86.0659"/>
            <defs>
              <linearGradient id="paint0_linear_4593_371" x1="34.8531" y1="19.2561" x2="278.527" y2="461.42" gradientUnits="userSpaceOnUse">
              <stop stopColor="#F17A5E"/>
              <stop offset="1" stopColor="#1F262F"/>
              </linearGradient>
            </defs>
          </motion.svg>
        </div>

        <div className="relative max-w-screen-lg px-8 m-auto pt-24">
          <h2 className="text-center font-bold text-primary-200 mb-12">
            <motion.span
              {...fadeInAnimateProps}
              custom={0}
              className="block text-2xl md:text-2xl lg:text-3xl xl:text-3xl"
            >
              we have just the right plans to
            </motion.span>
            <motion.span
              {...fadeInAnimateProps}
              custom={1}
              className="block mt-2 text-3xl md:text-3xl lg:text-4xl xl:text-5xl"
            >
              Suit your needs
            </motion.span>
          </h2>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-8 pb-8 md:pb-24">
            {priceTier.map((tier, i) => {
              return (
                <motion.div
                  {...fadeInAnimateProps}
                  custom={i}
                  key={i}
                  className="border border-solid border-[#FFFFFFAB] rounded-[10px] bg-secondary-500"
                >
                  <h3 className="px-8 py-8 border-b border-solid border-[#FFFFFFAB]">
                    <strong className="font-[600]">{tier.name}</strong>
                    <span className="block text-[#F9B675]">
                      {tier.description}
                    </span>
                  </h3>

                  <div className="px-8 py-12 md:py-16 relative">
                    {tier.recommended && (
                      <p className="px-8 pt-[0.2rem] pb-[0.48rem] text-s text-primary-900 rounded-bl-[28px] rounded-br-[28px] bg-yellow-200 inline-block m-auto absolute top-0 left-1/2 transform translate-x-[-50%] leading-tight">
                        Recommended
                      </p>
                    )}
                    <h4>Includes</h4>
                    <ul className="mt-4 md:mt-8 ">
                      {tier[router.query.country].map((item, j) => {
                        return (
                          <li
                            className="text-[#ffffff99] text-[0.8rem] mb-1/2 flex gap-2"
                            key={j}
                          >
                            <i>- </i> {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </motion.div>
              );
            })}
          </div>
          {/* <div className="pb-12 relative ">
            <div className="absolute top-0 left-[50%] block h-full w-full transform translate-x-[-50%] opacity-[30%] ">
              <ParallaxImage />
            </div>
            <motion.h2
              {...fadeInAnimateProps}
              custom={0}
              className="text-center font-bold text-primary-200 mb-12 relative z-2 pt-40"
            >
              <span className="block mt-2 text-4xl md:text-5xl lg:text-6xl xl:text-7xl">
                No, No, know
              </span>
            </motion.h2>
            {[
              "Loan-free access ",
              "no long term commitments",
              "no registration",
              "no paperwork",
              "no Insurance",
            ].map((item, i) => {
              return (
                <motion.p
                  {...fadeInAnimateProps}
                  custom={i}
                  key={i + 1}
                  className="block text-center text-2xl md:text-2xl lg:text-3xl xl:text-4xl mb-6 relative z-2"
                >
                  {item}
                </motion.p>
              );
            })}
          </div> */}
        </div>
      </div>
      <div className="bg-primary-200 py-8 min-h-[40rem] flex flex-col items-center justify-center">
        <motion.p
          {...fadeInAnimateProps}
          custom={0}
          className="text-center text-secondary-500 relative"
        >
          <span className="block m-auto max-w-[640px] text-2xl md:text-3xl text-[#5C5C5C]">
            Go ahead, call the shots.
          </span>
        </motion.p>

        <motion.h2
          {...fadeInAnimateProps}
          custom={1}
          className="text-center font-bold text-neutral-900 mt-4 relative"
        >
          <span className="block mt-2 text-3xl md:text-3xl lg:text-4xl xl:text-5xl">
            Own the car you have <br /> always wanted.
          </span>
        </motion.h2>
        <motion.div
          {...fadeInAnimateProps}
          custom={1}
          className="flex justify-center mt-8"
        >
          <Anchor
            className="bg-neutral-900 cursor-pointer text-lg text-neutral-50 rounded-none rounded-br-[20px] !px-10 !py-4 relative z-40 leading-[100%]"
            href={`/en/${router.query.country}/all-cars`}
            title="Join the Revolution"
            id="join-the-revolution"
          />
        </motion.div>
      </div>
    </div>
  );
};

export default HomepageTemplate;
